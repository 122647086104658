import functions from "./functions.js";

$(function () {

    const $form = $('.booking-form');
    const form = $form[0];

    if (!form) {
        return;
    }

    const booking = {
        $form: null,
        form: null,
        $payment: null,
        $calculation: null,
        calc: [],
        persons: null,
        promoDiscount: null,
        totalAmount: null,
        totalAmountConverted: null,
        insurance: null,
        status: null,

        init() {

            booking.$form = $(".booking-form");
            booking.form = booking.$form.first();

            if (! booking.$form.length) {
                return;
            }

            booking.isExcursion = booking.$form.attr('data-isexcursion');
            booking.objectId = booking.$form.attr('data-objectid');
            booking.$mainCalc = $("[data-guests-parentid").first();
            booking.mainCalcId = booking.$mainCalc.attr('data-guests-parentid');

            // BOOKING PAYMENT TYPE CHECKBOX
            if($('[name="paymentType"]')[0]) {
                checkPaymentType();
                $('[name="paymentType"]').on('change', e => {
                    checkPaymentType();
                });

                function checkPaymentType() {
                    $('[name="paymentType"]').each((index, item) => {
                        const $item = $(item);
                        const id = $item.attr('id');
                        $('[for=' + id + ']').find('[id='  + id + ']').prop('checked', $item.is(':checked'));
                    });
                }
            }

            // BOOKING PAYMENT MODEL CHECKBOX
            if($('[name="paymentModel"]').length) {
                checkPaymentModel();
                $('[name="paymentModel"]').on('change', e => {
                    const $target = $(e.currentTarget);
                    checkPaymentModel();

                    let installmentSupplement = $target.attr('data-installmentsupplement');
                    if(installmentSupplement) {
                        installmentSupplement = JSON.parse(installmentSupplement);
                        installmentSupplement.inCalc = 1;
                        let installmentExists = booking.calc.indexOf(installmentSupplement) > -1;
                        ! installmentExists && booking.calc.push(installmentSupplement);
                    } else {
                        $.each(booking.calc, function(key, item) {
                            if(item && item.itemDefinitionId.categoryId.key == 'installmentPayment') {
                                let index = booking.calc.indexOf(item);
                                booking.calc.splice(index, 1);
                            }
                        });
                    }
                });

                function checkPaymentModel() {
                    $('[name="paymentModel"]').each((index, item) => {
                        const $item = $(item);
                        const id = $item.attr('id');
                        $('[for=' + id + ']').find('[id='  + id + ']').prop('checked', $item.is(':checked'));
                    });
                }
            }

            booking.$calculation = $(".table-calculation");
            booking.$payment = $(".payment-details");
            booking.params = {};

            $('[name="guestFirstName0"]').on('change', e => {
                const $target = $(e.currentTarget);
                $('.holder-name').text($target.val());
            });

            booking.$calculation.find("*[data-calc]").each(function (index) {
                let item = JSON.parse($(this).attr('data-calc'));
                item.inCalc = item.isOptional ? 0 : 1;
                booking.calc.push(item);
            });

            booking.$calculation.find('input[type="checkbox"]').change(function (e) {
                let $row = $(this).closest('.calc-item');
                let $quantity = $row.find('[name=quantity]');
                let value = +$quantity.val();
                let checked = $(this).is(':checked') ? 1 : 0;

                if (checked && !value) {
                    $quantity.prop('value', 1);
                } else if (!checked && value) {
                    $quantity.prop('value', 0);
                }

                booking.calculate($(this));
            });

            booking.$calculation.find('input[type="checkbox"]').each((index, item) => {
                item.checked && $(item).first().trigger('change');
            });

            booking.setPeronVaucher();

            $('body').on('change', '[name^=departurePlace], [name^=seat]', function (e) {
                const $target = $(e.currentTarget);
                booking.selectionCalculate($target);
            });

            booking.$calculation.find('select.quantity').change(function (e) {
                let $row = $(this).closest('tr');
                let $checkbox = $row.find('input[type="checkbox"]');
                let value = +$(this).val();

                if (value && !$checkbox.is(':checked')) {
                    $checkbox.prop('checked', true);
                } else if (!value && $checkbox.is(':checked')) {
                    $checkbox.prop('checked', false);
                }

                booking.calculate($(this));
            });

            booking.total();

            booking.$form.on('submit', e => {
                //e.preventDefault();
                //e.stopPropagation();
                $('button[type="submit"]').attr('disabled', 'disabled');
                booking.submit();
            });

            booking.$pricingContainer = $(".table-calculation:first");
            booking.$partnerCodeSuccess = $(".partnerCodeSuccess");
            booking.$partnerCodeError = $(".partnerCodeError");

            $('#partnerCodeApply').on('click', function (e) {
                e.preventDefault();

                let params = functions.getUrlData(false);
                params.partnerCode = $("[name='partnerCode']").val();
                params.language = functions.getLang();

                if (params.partnerCode.indexOf('SA', 0) === -1)
                {
                    $.get('/services/promoCode/', $.param({ promoCode : params.partnerCode, language : params.language, objectId : booking.objectId })).then(response => {
                        response = JSON.parse(response);
                        booking.$pricingContainer.find(".promoDiscountItem").remove();

                        if (response.status && (+response.amount || +response.percent)) {
                            booking.$partnerCodeSuccess.removeClass('d-none');
                            booking.$partnerCodeError.addClass('d-none');

                            booking.applyDiscount(response.amount,  response.percent, response.perPerson, 'Promo kod ' + params.partnerCode.toUpperCase());
                        } else {
                            booking.$partnerCodeSuccess.addClass('d-none');
                            booking.$partnerCodeError.removeClass('d-none');
                        }
                    });
                    return;
                }

                booking.$partnerCodeSuccess.addClass('d-none');
                booking.$partnerCodeError.addClass('d-none');

                $.get('/services/booking_form_cart/', $.param(params)).then(response => {
                    response = JSON.parse(response);
                    if (response.status) {

                        let values = {};
                        booking.$pricingContainer.find("select").each((index, item) => {
                            values[$(item).attr('name')] = $(item).val();
                        });

                        booking.$pricingContainer.find('[type="checkbox"]:checked').not(':disabled').each((index, item) => {
                            values[$(item).attr('id')] = 1;
                        });

                        booking.$pricingContainer.html(response.html);
                        booking.$partnerCodeSuccess.removeClass('d-none');
                        booking.$partnerCodeError.addClass('d-none');

                        booking.$pricingContainer.find("select").each((index, item) => {
                            const $item = $(item);
                            $item.select2("destroy");
                            if ($item.attr('data-data')) {
                                $item.find("option").remove();
                                $item.select2({
                                    data: JSON.parse($item.attr('data-data'))
                                });
                            }
                            $item.select2({
                                placeholder: "",
                                allowClear: true
                            });
                            $item.val(values[$(item).attr('name')] || '').change();
                        });

                        booking.$pricingContainer.find('[type="checkbox"]').not(':disabled').each((index, item) => {
                            if (values[$(item).attr('id')]) {
                                $(item).attr('checked', true);
                            }
                        });

                        booking.setPeronVaucher();

                    } else {
                        booking.$partnerCodeSuccess.addClass('d-none');
                        booking.$partnerCodeError.removeClass('d-none');
                    }
                });
            });

        },
        setPeronVaucher() {
            // Check "Perosni vaučer" for Beograd
            const $placeFromId = $('[name^="placeFromId"]');
            let optionText = $placeFromId.find('option:selected').first().text();
            const $peronVaucher = $('[data-peronvaucher]');

            let isBeogradBasStanica = optionText == 'Beograd - BAS stanica';
            $peronVaucher.prop('checked', isBeogradBasStanica).trigger('change');
            isBeogradBasStanica && booking.calculate($peronVaucher.parents('.calc-item'));
            
            $placeFromId.on('change', e => {
                const $target = $(e.currentTarget);
                optionText = $target.find('option:selected').first().text();
                isBeogradBasStanica = optionText == 'Beograd - BAS stanica';

                $peronVaucher.prop('checked', isBeogradBasStanica).trigger('change');
                isBeogradBasStanica && booking.calculate($peronVaucher.parents('.calc-item'));
            });
        },
        applyDiscount(amount,  percent, promoPerPerson, itemTitle) {

            $(".table-calculation").each(function (index, elem) {
                let promoDiscountItem = JSON.parse($(elem).find(".calc-item.calc-item-mandatory:first").attr('data-calc'));
                let promoDiscountItemId = promoDiscountItem.id + '_' + 114;
                let total = 0;

                $.each(booking.calc, function (key, calc) {

                    if (calc && promoDiscountItem.calcId === calc.calcId) {
                        if (calc.inCalc && calc.itemDefinitionId.categoryId.key == 'baseRate') {
                            total += +calc.price;
                        }
                        if (calc.id == promoDiscountItemId) {
                            booking.calc.splice(key, 1);
                        }
                    }
                });

                let guests = promoDiscountItem.guests.split(',');
                guests = +guests[0] + +guests[1];

                let discountAmountPrice = 0.00;
                if (+amount) {
                    discountAmountPrice = (-1 * amount);
                } else {
                    discountAmountPrice = (-1 * (total * (percent/100)));
                }

                promoDiscountItem.id = promoDiscountItemId;
                promoDiscountItem.itemDefinitionId.id = 105;

                promoDiscountItem.itemDefinitionId.text = itemTitle;
                promoDiscountItem.itemTitle = {
                    name_sr: itemTitle
                };

                promoDiscountItem.quantity = 1;
                promoDiscountItem.itemDefinitionId.categoryId.id = 4;
                promoDiscountItem.itemDefinitionId.categoryId.key = 'discount';
                promoDiscountItem.itemDefinitionId.objectGroupId.id = 6;
                promoDiscountItem.itemDefinitionId.objectGroupId.key = 'excursions';
                promoDiscountItem.itemDefinitionId.typeId.id = 3;
                promoDiscountItem.itemDefinitionId.typeId.key = 'discount';
                promoDiscountItem.priceIn = 0;
                promoDiscountItem.price = discountAmountPrice;
                promoDiscountItem.totalIn = 0;
                promoDiscountItem.total = discountAmountPrice * promoDiscountItem.quantity;
                promoDiscountItem.skipCalculate = true;
                promoDiscountItem.inCalc = true;
                promoDiscountItem.perPerson = promoPerPerson ? 1 : 0;

                if ($(elem).find(".calc-item.calc-item-mandatory:last").hasClass('calc-hidden')) {
                    $('<div class="calc-item py-1 border-bottom promoDiscountItem" data-calc=\'' + JSON.stringify(promoDiscountItem) + '\'>\n' + '</div>').insertAfter($(elem).find(".calc-item.calc-item-mandatory:last"));
                } else {
                    $('<div class="calc-item py-1 border-bottom promoDiscountItem" data-calc=\'' + JSON.stringify(promoDiscountItem) + '\'>\n' +
                        '    <div class="row ">\n' +
                        '        <div class="col-lg">\n' +
                        '            <div class="small fw-medium">' + itemTitle + '</div>\n' +
                        '            \n' +
                        '            <input name="quantity" type="hidden" value="">\n' +
                        '            \n' +
                        '            \n' +
                        '        </div>' +
                        '        <div class="col d-lg-none"></div>\n' +
                        '        \n' +
                        '        \n' +
                        '        <div class="col-4 col-lg-2 text-end text-uppercase">\n' +
                        '           <div class="calc-item-price">€ ' + functions.formatMoney( promoDiscountItem.total, 2, ',', '.') + '</div>\n' +
                        '        </div>\n' +
                        '     </div>\n' +
                        '\n' +
                        '</div>').insertAfter($(elem).find(".calc-item.calc-item-mandatory:last"));
                }

                booking.calc.push(promoDiscountItem);
                booking.calculate($(".promoDiscountItem"));
            });
        },
        submit() {

            const $note = booking.$form.find('[name="note"]');
            let noteValue = $note.val();

            const $school0 = booking.$form.find('[name="school0"]');
            const school0Value = $school0.val();

            const $discover0 = booking.$form.find('[name="discover0"]');
            const discover0Value = $discover0.val();

            let notePrefix = '';
            notePrefix = (school0Value ? 'Fakultet / škola: ' + school0Value + '; ' : notePrefix);
            notePrefix = discover0Value ? (notePrefix + 'Za ' + (booking.isExcursion ? 'turu' : 'putovanje') + ' sam saznao/la preko: ' + discover0Value + '; ') : notePrefix;
            
            noteValue = notePrefix ? notePrefix + 'Napomena: ' + noteValue : noteValue;
            $note.val(noteValue);

            let baseRateIndex = 0;

            $.each(booking.calc, function(key, item) {
                if ( ! item.inCalc) {
                    delete booking.calc[key];
                } 
                else if (item.itemDefinitionId.categoryId.key == 'baseRate') {
                    let $guestsPerProduct = $("[data-guests-parentid='" + item.calcId + "']");
                    let guests = [];

                    if ($guestsPerProduct.length) {
                        let formData = functions.getFormData(booking.$form);

                        $guestsPerProduct.find('[data-guests]').each(function (index) {

                            let g = +$(this).data('guests');
                            let guestData = [];
                            
                            $.map(formData, (value, name) => {
                                if(name.endsWith(g)) {
                                    guestData[name] = value;
                                }
                            });

                            let firstName = guestData['guestFirstName' + g ];
                            let lastName = guestData['guestLastName' + g ];
                            let email = guestData['guestEmail' + g ] ? guestData['guestEmail' + g ] : null;
                            let phone = guestData['guestPhone' + g ] ? guestData['guestPhone' + g ] : null;
                            let documentNo = guestData['documentNo' + g ] ? guestData['documentNo' + g ] : null;
                            let city = guestData['city' + g ] ? guestData['city' + g ] : null;
                            //let note = guestData['note' + g ] ? guestData['note' + g ] : null;
                            
                            guests[index] = {
                                firstName : firstName,
                                lastName : lastName,
                                email : email,
                                phone: phone,
                                documentNo: documentNo,
                                city: city,
                                customerCity: city,
                                //note: note,
                            };
                        });

                        booking.calc[key].guestsData = guests;

                        if(baseRateIndex == 0) {
                            booking.params.firstName = guests[0].firstName;
                            booking.params.lastName = guests[0].lastName;
                            booking.params.email = guests[0].email;
                            booking.params.phone = guests[0].phone;
                            booking.params.city = guests[0].city;
                            booking.params.customerCity = guests[0].city;
                        }
                    }

                    baseRateIndex++;
                }
            });

            booking.params.paymentType = booking.$payment.find("[name='paymentType']:checked").val() ? booking.$payment.find("[name='paymentType']:checked").val() : 'bankTransfer';
            booking.params.paymentModelId = booking.$payment.find("[name='paymentModel']:checked").val() ? booking.$payment.find("[name='paymentModel']:checked").val() : booking.$payment.attr('data-paymentmodel');
            booking.params.calc = JSON.stringify(booking.calc);
            booking.params.requestId = Math.floor(Math.random() * ((9999999 - 1000000) + 1) + 1000000);

            $.each(booking.params, function (key, param) {
                $('<input>').attr({
                    type: 'hidden',
                    name: key,
                    value: param
                }).appendTo(booking.$form);
            });

        },
        calculate($elem) {
            let $row = $elem.closest('.calc-item');

            let $checkbox = $row.find('input[type="checkbox"]');
            let checked = $checkbox.is(':checked') ? 1 : 0;
            checked = $elem.attr('data-calc') ? 1 : checked;
            let id = $checkbox.attr('id');
            id = $elem.attr('data-id') ? $elem.attr('data-id') : id;

            //let $quantity = $row.find('select.quantity:visible');
            //let quantity = null;

            /*if ($quantity.length) {
                quantity = +$quantity.val();
            }*/

            $.each(booking.calc, function (key, calc) {

                if (calc.id == id) {

                    /*if (quantity == null) {
                        quantity = booking.calc[key].quantity;
                    }*/

                    booking.calc[key].inCalc = checked;
                    //booking.calc[key].quantity = (quantity || 1);
                    booking.calc[key].quantity = 1;
                    booking.calc[key].total = booking.itemTotal(booking.calc[key]);
                    booking.calc[key].totalConverted = booking.itemTotalConverted(booking.calc[key]);

                    $row.find('.item-total-amount').html(functions.formatMoney(booking.calc[key].total, 2, ',', '.'));
                    $row.find('.item-total-amount-converted').html(functions.formatMoney(booking.calc[key].totalConverted, 2, ',', '.'));
                }
            });


            booking.total();
        },
        selectionCalculate($target) {
            const value = $target.val();
            const data = $target.attr('data-data') ? JSON.parse($target.attr('data-data')) : [];

            if(data) {
                let dataIds = [];
                data.forEach(dataItem => {
                    dataIds.push(dataItem.itemUniqueId);
                });
                booking.calc = booking.calc.filter(calcItem => {
                    return ! dataIds.includes(calcItem.id);
                });
            }

            $target.attr('data-calc', value);

            if(value) {
                let item = JSON.parse(value);
                item.inCalc = item.isOptional ? 0 : 1;

                $target.attr('data-id', item.id);

                booking.calc.push(item);

            }

            booking.calculate($target);
        },
        itemTotal(item) {
            let itemTotal = 0.00;

            if(! booking.isExcursion) {
                item.quantity = 1;
            }

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek', 'Once']) > -1) {
                    itemTotal = +item.quantity * +item.price;
                } else {
                    itemTotal = +item.price;
                }
            } else {
                itemTotal = +item.quantity * +item.price;
            }

            return itemTotal;
        },
        itemTotalConverted(item) {
            let itemTotal = 0.00;

            if(! booking.isExcursion) {
                item.quantity = 1;
            }

            if (item.paymentTypeId.key) {

                if ($.inArray(item.paymentTypeId.key, ['perPerson', 'perPersonPerDay', 'perPersonPerWeek', 'Once']) > -1) {
                    itemTotal = +item.quantity * +item.priceConverted;
                } else {
                    itemTotal = +item.priceConverted;
                }
            } else {
                itemTotal = +item.quantity * +item.priceConverted;
            }

            return itemTotal;
        },
        total() {
            booking.totalAmount = 0.00;
            booking.totalAmountConverted = 0.00;

            $.each(booking.calc, function (key, calc) {
                if (!calc.onSpot && calc.inCalc && booking.mainCalcId == calc.calcId) {
                    booking.totalAmount += booking.itemTotal(calc);
                    booking.totalAmountConverted += booking.itemTotalConverted(calc);
                }
            });

            if($('[data-installmentSuppPrice]').length) {
                const installmentSuppPrice = $('[data-installmentSuppPrice]').attr('data-installmentSuppPrice');
                $('.total-amount-default').html(functions.formatMoney(booking.totalAmount, 2, ',', '.'));
                booking.totalAmount += parseFloat(installmentSuppPrice);
                $('.total-amount').html(functions.formatMoney(booking.totalAmount, 2, ',', '.'));
            } else {
                $('.total-amount').html(functions.formatMoney(booking.totalAmount, 2, ',', '.'));
            }

            let confirmationPayment = $('[data-confirmationPayment]').attr('data-confirmationpayment') ? $('[data-confirmationPayment]').attr('data-confirmationpayment') : 0;
            const confirmationPercent = $('[data-confirmationPercent]').attr('data-confirmationpercent') ? $('[data-confirmationPercent]').attr('data-confirmationpercent') : null;
            
            const advancePaymentPercent = $('[data-advancePaymentPercent]').attr('data-advancepaymentpercent');
            let advance = advancePaymentPercent ? booking.totalAmount * (advancePaymentPercent / 100) : null;

            confirmationPayment = confirmationPercent ? booking.totalAmount * (confirmationPercent / 100) : confirmationPayment;
            if(confirmationPayment) {
                advance = advancePaymentPercent ? (booking.totalAmount - confirmationPayment) * (advancePaymentPercent / 100) : null;
            }

            if(! advance) {
                const advanceConfirmationAmount = $('[data-advanceConfirmationAmount]').attr('data-advanceconfirmationamount');
                advance = advanceConfirmationAmount;
            }

            const rest = (booking.totalAmount - confirmationPayment) - advance;

            let passedConfirmation = $('.advance').attr('data-confirmationpayment');
            if(passedConfirmation) advance = (+advance) + (+passedConfirmation);

            $('.confirmationPayment').html(functions.formatMoney(confirmationPayment, 2, ',', '.'));
            $('.advance').html(functions.formatMoney(advance, 2, ',', '.'));
            $('.rest').html(functions.formatMoney(rest, 2, ',', '.'));

        }
    }

    booking.init();
});